import styled from "styled-components"
import React, { FC } from "react"

import Footer from "@components/Footer"
import Header from "@components/Header"

interface T {
  className?: string
}
const Layout: FC<T> = ({children, className}) => {
  return (
    <>
      <Header />
      <main className={className}>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
