import React from "react"

const Header = () => {
  return (
    <link
      href="https://fonts.googleapis.com/css?family=Alfa+Slab+One&display=swap"
      rel="stylesheet"
    />
  )
}
export default Header
